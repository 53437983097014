import React, { Component, createRef } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import FormHelperText from '@material-ui/core/FormHelperText'


class MailingForm extends Component {
  state = {
    open: false,
  }

  constructor(props) {
    super(props)
    this.email = createRef()
    this.listFields = createRef()
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  _handleSubmit = async (e) => {
    e.preventDefault()
    const result = await addToMailchimp(this.email.current.value)

    if (result.result === 'error') {
      this.setState({ error: result.msg })
    } else {
      this.setState({ open: false, result })
    }
  }

  render() {
    return (
      <div>
        <Button variant="outlined" onClick={this.handleClickOpen}>
          + Info
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
            <form onSubmit={this._handleSubmit}>
              <DialogContent>
                  <DialogContentText className="mb-2">
                    To subscribe to this website, please enter your email address here. We will send
                    updates occasionally.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    inputRef={this.email}
                    aria-describedby="component-error-text"
                    fullWidth
                    error={(this.state.error) ? true : false }
                  />
                  {
                    (this.state.error)
                      ? <FormHelperText error id="component-error-text">{this.state.error}</FormHelperText>
                      : null
                  }
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="#343a40">
                  Cancel
                </Button>
                <Button color="#343a40" type="submit">
                  Subscribe
                </Button>
              </DialogActions>
            </form>
        </Dialog>
      </div>
    )
  }
}

export default withMobileDialog()(MailingForm)