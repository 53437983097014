// import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import MailingForm from './mailingForm'

const Header = ({ siteTitle }) => (
  <div>
    {/* <Link
      to="/"
      style={{
        color: `white`,
        textDecoration: `none`,
      }}
    >
      {siteTitle}
    </Link> */}

    <nav className="navbar navbar-light fixed-top justify-content-end">
      {/* <Link
        className="text-main pull-right"
        to="/alternate"
        getProps={({ isPartiallyCurrent }) => { return isPartiallyCurrent ? { to: "/alternate" } : { to: "active" } }}
      >alternate</Link> */}

      <MailingForm />

    </nav>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Raw Capital`,
}

export default Header
